<div class="page-container">
  <h1 style="display: flex; justify-content: center">Cost Estimator</h1>
  <form class="estimator-form" [formGroup]="costEstimatorForm">
    <mat-form-field class="diseases">
      <mat-label>Diseases</mat-label>
      <mat-select
        id="diseases"
        formControlName="diseases"
        (selectionChange)="onDiseasesChange()"
        multiple>
        <mat-option *ngFor="let option of diseasesSelection" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="dosage">
      <mat-label>Dosage</mat-label>
      <input
        id="dosage"
        type="number"
        min="0"
        maxlength="3"
        matInput
        formControlName="dosage" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Weight</mat-label>
      <input
        id="weight"
        type="number"
        min="0"
        maxlength="3"
        matInput
        placeholder="0"
        formControlName="weight" />
      <mat-button-toggle-group
        name="weightUnit"
        aria-label="weightUnit"
        matSuffix
        (change)="onUnitChange()"
        formControlName="weightUnit"
        style="height: 44px">
        <mat-button-toggle value="kg">kg</mat-button-toggle>
        <mat-button-toggle value="lb">lb</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-form-field>
    <mat-radio-group
      id="prescription"
      class="prescription"
      formControlName="prescription"
      aria-label="rangeType"
      (change)="filterPrescriptionBrands()">
      <mat-radio-button [value]="false">All</mat-radio-button>
      <mat-radio-button [value]="true">Prescription only</mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="treatmentType">
      <mat-label>Treatment Type</mat-label>
      <mat-select
        id="treatmentType"
        formControlName="treatmentType"
        (selectionChange)="treatmentTypeChange($event.value)">
        <mat-option *ngFor="let type of treatmentTypes" value="{{ type }}">{{
          type
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="medicationBrand">
      <mat-label>Medication Brand</mat-label>
      <mat-select
        id="medicationBrand"
        formControlName="medicationBrand"
        (selectionChange)="onMedicationBrandChange()">
        <mat-option
          *ngFor="let medicationBrand of filteredSelectionBrands"
          [value]="medicationBrand">
          {{ medicationBrand.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <button
      mat-flat-button
      color="primary"
      (click)="copyLink()">
      <mat-icon
        fontIcon="share"></mat-icon>
      Copy link
    </button> -->
  </form>
  <div class="cost-estimator-content" *ngIf="medicationBrand.value">
    <app-banner [icon]="'info'" spacing="compact" style="margin-bottom: 1rem"
      >This is your treatment cost estimate at current weight over 84 days. This
      is NOT due up front, it is pay as you go.
    </app-banner>
    <ng-template
      *ngTemplateOutlet="
        estimateTable;
        context: { medicationBrand: medicationBrand.value }
      "></ng-template>
  </div>
</div>

<ng-template #estimateTable let-medicationBrand="medicationBrand">
  <h2>{{ medicationBrand?.name }}</h2>
  <div class="estimation-table">
    <div class="table-row">
      <div class="label">Price per {{ items.slice(0, -1) }}:</div>
      <div class="value">{{ getPrice(medicationBrand) | currency }}</div>
    </div>
    <div class="table-row">
      <div class="label">
        Absorbable {{ items === "vials" ? "Vial" : "Pill" }} Concentration:
      </div>
      <div class="value">
        {{ medicationBrand.concentration }} {{ medicationBrand.unit }}
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="table-row">
      <div class="label">Total for Injections 84 Days:</div>
      <div class="value">{{ getTotalCost(medicationBrand) | currency }}</div>
    </div>
    <div class="table-row">
      <div class="label">
        Daily Dose in {{ items === "vials" ? "ML" : "Number of Pills" }}:
      </div>
      <div class="value">{{ getDailyDose(medicationBrand) }}</div>
    </div>
    <div class="table-row">
      <div class="label">Total Number of {{ items }} at current weight:</div>
      <div class="value">{{ getTotalItems(medicationBrand) }}</div>
    </div>
    <div class="table-row">
      <div class="label">Number of {{ items }} per week at current weight:</div>
      <div class="value">{{ getWeeklyItems(medicationBrand) }}</div>
    </div>
    <div class="table-row">
      <div class="label">Percentage of treatment covered if relapse:</div>
      <div class="value">{{ medicationBrand.relapseCoverage }}%</div>
    </div>
  </div>
</ng-template>
